import React, { useCallback, useState } from 'react';
import { HelperText, useTheme } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useMutation, useQueryClient } from 'react-query';
import { useI18n } from '../../context/I18nContext';
import ConfirmationRow from '../../payment/components/ConfirmationRow';
import Container from '../../components/Container';
import { NavigationDialog } from '../CommonDialogs';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { SubscriptionsStackRouteParams } from '../../navigators/SubscriptionsStackNavigator';
import Typography from '../../components/Typography';
import Spacer from '../../components/Spacer';
import { cancelSubscription, invalidateSubscriptionData, useGetProductSubscription } from '../../apis/subscriptionApis';
import { flattenDict } from '../../utils/helpers';

export interface ConfirmSubscriptionCancelDialogRouteParams {
  id: string;
}

type Props = CompositeScreenProps<
  StackScreenProps<SubscriptionsStackRouteParams, 'ConfirmSubscriptionCancelDialog'>,
  AppDrawerScreenProp
>;

export function ConfirmSubscriptionCancelDialog({ navigation, route }: Props) {
  const { colors } = useTheme();
  const mp = useMixpanel();
  const queryClient = useQueryClient();
  const { I18n, formatDate } = useI18n();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { timeZone } = useMapViewFilter();

  // TODO: Check if this is getting the subscription from cache or fetching it from backend again, cache would be preferred
  const getProductSubscriptionResult = useGetProductSubscription(route.params.id);

  // TODO: handle either of these being undefined
  const subscription = getProductSubscriptionResult.data?.data?.getProductSubscription;
  const product = subscription?.product;

  const cancelSubscriptionMutation = useMutation(
    async (input: Parameters<typeof cancelSubscription>) => {
      return cancelSubscription(...input);
    },
    {
      onSuccess(data) {
        mp?.track('Subscription cancel success', flattenDict('subscription', data.data?.makeAProductSubscription));
        mp?.getPeople().increment('Subscription cancellations', 1);
        invalidateSubscriptionData(queryClient);
        navigation.goBack();
      },
      onError(error: any) {
        mp?.track('Subscription cancel error', error ?? null);
        setErrorMessage(`${I18n.t('subscriptions.confirmSubscriptionCancelModal.failTitle')}: ${error?.message}`);
      },
    },
  );

  const handleUserDismissed = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NavigationDialog
      style={{ backgroundColor: colors.elevation.level2 }} // TODO: is this correct?
      title={I18n.t('subscriptions.confirmSubscriptionCancelModal.title')}
      description={''}
      onDismiss={handleUserDismissed}
      actions={[
        {
          title: I18n.t('subscriptions.confirmSubscriptionCancelModal.cancel'),
          onPress: handleUserDismissed,
          disable: cancelSubscriptionMutation.isLoading,
        },
        {
          title: I18n.t('subscriptions.confirmSubscriptionCancelModal.confirm'),
          loading: cancelSubscriptionMutation.isLoading,
          disable: cancelSubscriptionMutation.isLoading,
          onPress: () => {
            return cancelSubscriptionMutation.mutate([{ id: route.params.id }]);
          },
        },
      ]}
      iconName={'check'}
    >
      {/* Product */}
      {/* TODO: What icon? */}
      <ConfirmationRow title={product?.name ?? '?'} iconName="calendar" />

      <Spacer />
      <Typography variant="body2">
        {I18n.t('subscriptions.confirmSubscriptionCancelModal.cancellationEffectiveOn', {
          date: formatDate(new Date(subscription?.renewalDate ?? ''), 'dateWithYear', timeZone),
        })}
      </Typography>

      {/* Error text */}
      <Container>
        <HelperText type={'error'} visible={!!errorMessage}>
          {errorMessage}
        </HelperText>
      </Container>
    </NavigationDialog>
  );
}
