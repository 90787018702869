import { QueryClient, useQuery } from 'react-query';
import { Auth } from 'aws-amplify';
import { appsyncGraphql, publicAppsyncGraphql } from './appsyncHelper';
import {
  MutationMakeAProductSubscriptionArgs,
  QueryGetMyProductSubscriptionsArgs,
  QueryGetProductByIdArgs,
  ProductSubscriptionStatus,
  QueryGetProductSubscriptionArgs,
  MutationCancelAProductSubscriptionArgs,
} from '../types/appsync-types';
import { useMyProfileQuery } from './appsyncApis';
import { invalidateReservationData } from './reservationApis';

useGetProducts.id = 'useGetProducts';

export function useGetProducts() {
  return useQuery(
    [useGetProducts.id],
    async () => {
      const query = /* GraphQL */ `
        query GetProducts {
          getProducts {
            id
            name
            price
            renewInterval
            owner {
              name
            }
            subscription {
              id
              currency
              price
              status
              renewalDate
            }
          }
        }
      `;

      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        /* */
      }
      if (user) {
        return appsyncGraphql<'getProducts'>(query);
      }
      return publicAppsyncGraphql<'getProducts'>(query);
    },
    {},
  );
}

useGetProductById.id = 'useGetProductById';

export function useGetProductById(productId: string | undefined) {
  return useQuery(
    [useGetProductById.id, productId],
    async () => {
      const variables: QueryGetProductByIdArgs = {
        id: productId!,
      };
      const query = /* GraphQL */ `
        query GetProductById($id: ID!) {
          getProductById(id: $id) {
            id
            name
            price
            renewInterval
            linkedPods {
              localRef
              title
              building {
                title
              }
            }
            owner {
              name
            }
            subscription {
              id
              price
              currency
              startDate
              renewalDate
              cancelDate
              status
              card {
                id
                last4
                brand
              }
            }
          }
        }
      `;

      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        /* */
      }
      if (user) {
        return appsyncGraphql<'getProductById'>(query, variables);
      }
      return publicAppsyncGraphql<'getProductById'>(query, variables);
    },
    { enabled: !!productId },
  );
}

getMySubscriptions.id = 'getMySubscriptions';

function getMySubscriptions() {
  const query = /* GraphQL */ `
    query GetMyProductSubscriptions {
      getMyProductSubscriptions {
        id
        productId
        status
        userId
        receiptUrl
        card {
          id
          last4
          brand
        }
        product {
          id
        }
      }
    }
  `;
  return appsyncGraphql<'getMyProductSubscriptions'>(query);
}

useGetMyProductSubscriptions.id = 'useGetMyProductSubscriptions';

export function useGetMyProductSubscriptions() {
  return useQuery(
    [useGetMyProductSubscriptions.id],
    async () => {
      const query = /* GraphQL */ `
        query GetMyProductSubscriptions {
          getMyProductSubscriptions {
            id
            productId
            currency
            price
          }
        }
      `;
      return appsyncGraphql<'getMyProductSubscriptions'>(query);
    },
    {},
  );
}

useGetProductSubscription.id = 'useGetProductSubscription';

export function useGetProductSubscription(subscriptionId: string | undefined) {
  return useQuery(
    [useGetProductSubscription.id, subscriptionId],
    async () => {
      const variables: QueryGetProductSubscriptionArgs = {
        id: subscriptionId!,
      };
      const query = /* GraphQL */ `
        query GetProductSubscription($id: ID!) {
          getProductSubscription(id: $id) {
            id
            price
            currency
            startDate
            renewalDate
            cancelDate
            card {
              id
              last4
              brand
            }
            product {
              id
              name
              price
              linkedPods {
                localRef
                title
                building {
                  title
                }
              }
              owner {
                name
              }
            }
          }
        }
      `;

      return appsyncGraphql<'getProductSubscription'>(query, variables);
    },
    { enabled: !!subscriptionId },
  );
}

function combineStatus(a: ProductSubscriptionStatus, b: ProductSubscriptionStatus): ProductSubscriptionStatus {
  if ([a, b].includes(ProductSubscriptionStatus.MISSING_PAYMENT)) {
    return ProductSubscriptionStatus.MISSING_PAYMENT;
  }
  if ([a, b].includes(ProductSubscriptionStatus.ACTIVE)) {
    return ProductSubscriptionStatus.ACTIVE;
  }
  if ([a, b].includes(ProductSubscriptionStatus.ENDED)) {
    return ProductSubscriptionStatus.ENDED;
  }
  if ([a, b].includes(ProductSubscriptionStatus.CANCELLED)) {
    return ProductSubscriptionStatus.CANCELLED;
  }
  if ([a, b].includes(ProductSubscriptionStatus.FAILED)) {
    return ProductSubscriptionStatus.FAILED;
  }
  return a ?? b;
}

export function createSubscription(input: MutationMakeAProductSubscriptionArgs) {
  const mutation = /* GraphQL */ `
    mutation MakeAProductSubscription($input: ProductSubscriptionInput) {
      makeAProductSubscription(input: $input) {
        id
        status
        currency
        userId
        productId
        product {
          id
          name
          price
        }
      }
    }
  `;
  return appsyncGraphql<'makeAProductSubscription'>(mutation, input);
}

export function cancelSubscription(input: MutationCancelAProductSubscriptionArgs) {
  const mutation = /* GraphQL */ `
    mutation CancelASubscription($id: ID!) {
      cancelAProductSubscription(id: $id) {
        id
      }
    }
  `;
  return appsyncGraphql<'makeAProductSubscription'>(mutation, input);
}

export function invalidateSubscriptionData(queryClient: QueryClient) {
  queryClient.invalidateQueries(getMySubscriptions.id);
  queryClient.invalidateQueries(useGetProductSubscription.id);
  queryClient.invalidateQueries(useGetMyProductSubscriptions.id);
  queryClient.invalidateQueries(useGetProductById.id);
  queryClient.invalidateQueries(useGetProducts.id);

  invalidateReservationData(queryClient);
}
