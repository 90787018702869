import React, { FC } from 'react';
import { List, TextInput, useTheme } from 'react-native-paper';
import { SimpleLineIcons, Ionicons, MaterialIcons, MaterialCommunityIcons, FontAwesome6 } from '@expo/vector-icons';
import { TextProps } from 'react-native';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';

export type UiDefaultIconName = keyof typeof SimpleLineIcons.glyphMap;

type UiIconCommonProps = {
  /**
   * Size of the icon, can also be passed as fontSize in the style object.
   *
   * @default 12
   */
  size?: number | undefined;

  /**
   * Color of the icon
   *
   */
  color?: string | undefined;
};

export type UiIconDefault = {
  set?: undefined;
  name: UiDefaultIconName;
};

export type UiIconSimple = {
  set: 'simple';
  name: keyof typeof SimpleLineIcons.glyphMap;
};

export type UiIconIon = {
  set: 'ion';
  name: keyof typeof Ionicons.glyphMap;
};

export type UiIconMaterial = {
  set: 'material';
  name: keyof typeof MaterialIcons.glyphMap;
};
export type UiIconMaterialCommunity = {
  set: 'material-community';
  name: keyof typeof MaterialCommunityIcons.glyphMap;
};

export type UiIconFontAwesome6 = {
  set: 'fontawesome6';
  name: keyof typeof FontAwesome6.glyphMap;
};

export type UiIconType =
  | UiIconDefault
  | UiIconSimple
  | UiIconIon
  | UiIconMaterial
  | UiIconMaterialCommunity
  | UiIconFontAwesome6;
export type UiIconProps = UiIconCommonProps & UiIconType;

export const UiIcon: FC<UiIconProps & TextProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, set, ...other } = props;

  switch (props.set) {
    case undefined:
    case 'simple':
      return <SimpleLineIcons name={props.name} {...other} />;
    case 'ion':
      return <Ionicons name={props.name} {...other} />;
    case 'material':
      return <MaterialIcons name={props.name} {...other} />;
    case 'material-community':
      return <MaterialCommunityIcons name={props.name} {...other} />;
    case 'fontawesome6':
      return <FontAwesome6 name={props.name} {...other} />;
  }
  return null;
};

const ListIcon = (props: UiIconProps) => {
  const { colors } = useTheme();
  return <List.Icon icon={(generatorProps) => <UiIcon {...generatorProps} color={colors.primary} {...props} />} />;
};

export const listIconGenerator = (props: UiIconProps | UiDefaultIconName) => {
  if (typeof props === 'string') {
    return () => <ListIcon name={props} />;
  }
  return () => <ListIcon {...props} />;
};

export const uiIconSourceGenerator = (props: UiIconProps): IconSource => {
  return (sourceProps) => {
    return (
      <UiIcon
        {...props}
        size={props.size ?? sourceProps.size}
        color={props.color ?? sourceProps.color}
        style={{ textAlign: 'center' }}
      />
    );
  };
};

export const TextInputIcon = (props: UiIconProps) => {
  return <TextInput.Icon icon={uiIconSourceGenerator(props)} />;
};
