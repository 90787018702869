import { ProductRenewInterval } from '../types/appsync-types';

export function getRenewIntervalTranslationKeys(renewInterval: ProductRenewInterval): {
  singular: string;
  plural: string;
  abbreviation: string;
} {
  switch (renewInterval) {
    case ProductRenewInterval.DAILY:
      return { singular: 'datetime.day', plural: 'datetime.days', abbreviation: 'datetime.daysAbbr' };
    case ProductRenewInterval.WEEKLY:
      return { singular: 'datetime.week', plural: 'datetime.weeks', abbreviation: 'datetime.weeksAbbr' };
    case ProductRenewInterval.MONTHLY:
      return { singular: 'datetime.month', plural: 'datetime.months', abbreviation: 'datetime.monthsAbbr' };
    case ProductRenewInterval.YEARLY:
      return { singular: 'datetime.year', plural: 'datetime.years', abbreviation: 'datetime.yearsAbbr' };
  }
}
