import React from 'react';
import { List } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useI18n } from '../context/I18nContext';
import { screenNames } from '../navigators/screenNames';
import { useExtractedReservationData } from '../apis/reservationApis';
import Loading from '../components/Loading';
import ScrollablePage from '../components/ui/ScrollablePage';
import { AppDrawerNavigationProp } from '../components/DrawerMenuContent';
import { SummaryHeaderProps } from '../components/SummaryHeader';
import { ListItemRow } from '../components/ListItemRow';
import { CompoundReservation } from '../types/misc.types';

const BookingItem = ({ item }: { item: CompoundReservation }) => {
  const navigation = useNavigation<AppDrawerNavigationProp>();
  return (
    <ListItemRow
      title={item.spaceInfo.title}
      description={`#${item.spaceId}, ${item.spaceInfo.building?.title}`}
      titleNumberOfLines={1}
      descriptionNumberOfLines={1}
      onPress={() => {
        navigation.navigate('me', {
          screen: screenNames.BookingDetail,
          params: {
            bookingId: item.initialId,
          },
        });
      }}
      leftIcon={{ name: 'square-parking', set: 'fontawesome6' }}
      type="navigation"
    />
  );
};

export default function BookingView() {
  const { I18n } = useI18n();

  const { isLoading, upcomingReservations, ongoingReservations, pastReservations } = useExtractedReservationData();

  if (isLoading) {
    return <Loading />;
  }

  function summaryData(): SummaryHeaderProps {
    // if (ongoingReservations && ongoingReservations.length > 0) {
    //   const res = ongoingReservations[0]!;
    //   return {
    //     title: I18n.t('bookings.summaries.reservations.title'),
    //     description: I18n.t('bookings.summaries.reservations.description', {
    //       id: `#${ongoingReservations[0]!.spaceId}`,
    //       date: formatDate(new Date(res.from!), 'dateWithYear-no-tz', res.timeZone),
    //       startTime: formatDate(new Date(res.from!), 'time-no-tz', res.timeZone),
    //       endTime: formatDate(new Date(res.to!), 'time', res.timeZone),
    //     }),
    //     icon: 'book-open',
    //     button: {
    //       label: I18n.t('bookings.summaries.reservations.buttonTitle'),
    //       onPress: () => {
    //         navigation.navigate('me', {
    //           screen: screenNames.BookingDetail,
    //           params: {
    //             bookingId: ongoingReservations[0]!.initialId,
    //           },
    //         });
    //       },
    //     },
    //   };
    // }
    // if (upcomingReservations.length > 0 && upcomingReservations[0]) {
    //   const firstUpcomingReservation = upcomingReservations[0];
    //   const tz = firstUpcomingReservation.spaceInfo.building?.timezone;
    //   return {
    //     title: I18n.t('bookings.summaries.upcomingReservations.title'),
    //     description: I18n.t('bookings.summaries.upcomingReservations.description', {
    //       id: `#${firstUpcomingReservation.spaceId}`,
    //       date: formatDate(firstUpcomingReservation.from, 'dateWithYear-no-tz', tz),
    //       startTime: formatDate(firstUpcomingReservation.from, 'time-no-tz', tz),
    //       endTime: formatDate(firstUpcomingReservation.to, 'time', tz),
    //     }),
    //     icon: 'book-open',
    //     button: {
    //       label: I18n.t('bookings.summaries.upcomingReservations.buttonTitle'),
    //       onPress: () => {
    //         navigation.navigate('me', {
    //           screen: screenNames.BookingDetail,
    //           params: {
    //             bookingId: firstUpcomingReservation.initialId,
    //           },
    //         });
    //       },
    //     },
    //   };
    // }
    // return {
    //   title: I18n.t('bookings.summaries.noReservations.title'),
    //   description: I18n.t('bookings.summaries.noReservations.description'),
    //   icon: 'book-open',
    //   // button: {
    //   //   label: I18n.t('bookings.summaries.noReservations.buttonTitle'),
    //   //   onPress: () => {
    //   //     navigation.navigate('map', { screen: 'MapView' });
    //   //   },
    //   // },
    // };
    return {
      title:
        ongoingReservations?.length || upcomingReservations?.length
          ? I18n.t('bookings.summaries.title')
          : I18n.t('bookings.summaries.noActiveTitle'),
      description: I18n.t('bookings.summaries.description'),
      icon: 'lock-open',
    };
  }

  return (
    <ScrollablePage summary={summaryData()}>
      {upcomingReservations?.length ? <List.Subheader>{I18n.t('bookings.nextBookings')}</List.Subheader> : null}
      {upcomingReservations?.length
        ? upcomingReservations.map((item) => <BookingItem key={item!.initialId} item={item!} />)
        : null}

      {pastReservations?.length ? <List.Subheader>{I18n.t('bookings.pastBookings')}</List.Subheader> : null}
      {pastReservations?.length
        ? pastReservations.map((item) => <BookingItem key={item!.initialId} item={item!} />)
        : null}
    </ScrollablePage>
  );
}
