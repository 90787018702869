import React, { useCallback, useMemo } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import type { DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer';
import Constants from 'expo-constants';
import { drawerScreens, screenNames } from './screenNames';
import DrawerMenuContent, { DrawerRouteParams } from '../components/DrawerMenuContent';
import { AccessLevel, useAuth } from '../context/AuthContext';
import LocationsStackNavigator from './LocationsStackNavigator';
import InformationStackNavigator from './InformationStackNavigator';
import { MapStackNavigator } from './MapStackNavigator';
import QRCodeStackNavigator from './QRCodeStackNavigator';
// import NewsStackNavigator from './NewsStackNavigator';
import { HelpStackNavigator } from './HelpStackNavigator';
import AdminStackNavigator from './AdminStackNavigator';
import { UiIconType } from '../components/SimpleListIcon';
import SubscriptionsStackNavigator from './SubscriptionsStackNavigator';

export type MenuItemType = {
  translationKey: string;
  uiIcon: UiIconType;
  screenStackName: keyof DrawerRouteParams;
  screenParams?: Record<string, any>;
  nav: any;
  visibleAccessLevel: AccessLevel;
  requiredAccessLevel: AccessLevel;
  hidden?: boolean;
};

export const MENU_ITEMS: MenuItemType[] = [
  {
    translationKey: 'drawerMenu.map',
    uiIcon: { name: 'map' },
    screenStackName: drawerScreens.MapStack,
    nav: MapStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.PUBLIC,
  },
  {
    translationKey: 'drawerMenu.subscriptions',
    uiIcon: { name: 'basket' },
    screenStackName: drawerScreens.SubscriptionsStack,
    nav: SubscriptionsStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.PUBLIC,
  },
  {
    translationKey: 'drawerMenu.myInfo',
    uiIcon: { name: 'user' },
    screenStackName: drawerScreens.MyInfoStack,
    nav: InformationStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.AUTHENTICATED,
  },
  {
    translationKey: 'drawerMenu.locations',
    uiIcon: { name: 'pin' },
    screenStackName: drawerScreens.LocationsStack,
    nav: LocationsStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.PUBLIC,
  },
  {
    translationKey: 'drawerMenu.scanQrCode',
    uiIcon: { name: 'qr-code-outline', set: 'ion' },
    screenStackName: drawerScreens.QRCodeStack,
    screenParams: {
      sourceScreen: screenNames.QRScanScreen,
      mode: 'UNLOCK',
    },
    nav: QRCodeStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.PUBLIC,
    hidden: Constants.manifest?.extra?.hideScanningSpaceQRCode,
  },
  // {
  //   translationKey: 'drawerMenu.news',
  //   uiIcon: { name: 'feed' },
  //   screenStackName: drawerScreens.NewsStack,
  //   nav: NewsStackNavigator,
  //   visibleAccessLevel: AccessLevel.PUBLIC,
  //   requiredAccessLevel: AccessLevel.PUBLIC,
  // },

  {
    translationKey: 'drawerMenu.help',
    uiIcon: { name: 'support' },
    screenStackName: drawerScreens.HelpStack,
    nav: HelpStackNavigator,
    visibleAccessLevel: AccessLevel.PUBLIC,
    requiredAccessLevel: AccessLevel.PUBLIC,
  },
  {
    translationKey: 'drawerMenu.admin',
    uiIcon: { name: 'diamond' },
    screenStackName: drawerScreens.AdminStack,
    nav: AdminStackNavigator,
    visibleAccessLevel: AccessLevel.SUPER_ADMIN,
    requiredAccessLevel: AccessLevel.SUPER_ADMIN,
  },
];

const Drawer = createDrawerNavigator<DrawerRouteParams>();

function DrawerMenu() {
  const { userAccessLevel } = useAuth();
  const { width } = useWindowDimensions();
  const showSidebar = width > 600;

  /* These visible items need to be in sync with the ones in DrawerMenuContent */
  const visibleItems: MenuItemType[] = useMemo(
    () => MENU_ITEMS.filter((x) => !x.hidden && x.visibleAccessLevel <= userAccessLevel),
    [userAccessLevel],
  );
  const wrapContent = useCallback(
    (props: DrawerContentComponentProps) => <DrawerMenuContent items={visibleItems} {...props} />,
    [visibleItems],
  );

  const opts: DrawerNavigationOptions = useMemo(() => {
    return {
      header: () => null, // Hides the default header for drawer as our stack navigators use custom header.
      drawerStyle: styles.drawerStyle,
      drawerType: showSidebar ? 'permanent' : 'front',
    };
  }, [showSidebar]);

  return (
    <Drawer.Navigator drawerContent={wrapContent} initialRouteName={drawerScreens.MapStack} screenOptions={opts}>
      {visibleItems.map((item) => (
        <Drawer.Screen
          key={item.translationKey}
          name={item.screenStackName}
          component={item.nav}
          options={{ unmountOnBlur: item.screenStackName !== 'map' }}
        />
      ))}
    </Drawer.Navigator>
  );
}
const styles = StyleSheet.create({
  drawerStyle: {
    maxWidth: 280,
    width: '80%',
    borderRightWidth: 0, // Removes the white border from the drawer that appears on web broswer
  },
});
export default DrawerMenu;
