import React, { useCallback } from 'react';
import { Chip, useTheme } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeScreenProps, useNavigation } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { t } from 'i18n-js';
import { useI18n } from '../../context/I18nContext';
import { screenNames } from '../../navigators/screenNames';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { AuthState, useAuth } from '../../context/AuthContext';
import { ListItemRow } from '../../components/ListItemRow';
import { Currency, ProductRenewInterval, ProductSubscriptionStatus } from '../../types/appsync-types';
import Typography from '../../components/Typography';
import { UiIcon } from '../../components/SimpleListIcon';
import { SubscriptionsStackRouteParams } from '../../navigators/SubscriptionsStackNavigator';
import { useGetProducts } from '../../apis/subscriptionApis';
import Loading from '../../components/Loading';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { getRenewIntervalTranslationKeys } from '../../utils/getRenewIntervalTranslationKeys';

function SubscriptionItem({
  name,
  productId,
  price,
  owner,
  currency,
  active,
  canceled,
  renewalDate,
  renewInterval,
}: {
  name: string;
  productId: string;
  price: number | undefined | null; // TODO: What to do if price is undefined or null?
  owner: string | undefined | null;
  currency: Currency;
  active: boolean;
  canceled: boolean;
  renewalDate: string | undefined | null;
  renewInterval: ProductRenewInterval;
}) {
  const { formatCurrency, formatDate } = useI18n();
  const { colors } = useTheme();
  const { I18n } = useI18n();
  const { timeZone } = useMapViewFilter();
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const onPress = useCallback(() => {
    navigation.navigate(screenNames.SubscriptionDetailScreen, {
      productId,
    });
  }, [navigation, productId]);

  const renderTitle = () => {
    return (
      <Typography variant="body1">
        {name}
        {active && (
          <Typography variant="body1">
            {' • '}
            <Typography variant="body1" color={colors.primary} style={{ fontWeight: 'bold' }}>
              {canceled && renewalDate
                ? I18n.t('subscriptions.activeUntil', {
                    date: formatDate(new Date(renewalDate), 'dateWithYear', timeZone),
                  })
                : I18n.t('subscriptions.active')}
            </Typography>
          </Typography>
        )}
      </Typography>
    );
  };

  const descriptionOwner = owner ? `${owner} •` : '';

  return (
    <ListItemRow
      title={renderTitle}
      description={`${descriptionOwner} ${
        formatCurrency(price, currency) + ' / ' + I18n.t(getRenewIntervalTranslationKeys(renewInterval).abbreviation)
      }`}
      leftIcon={active ? { name: 'check', set: 'material' } : { name: 'calendar', set: 'ion' }}
      onPress={onPress}
      accessibilityRole="button"
      type="navigation"
    />
  );
}

export type SubscriptionsRouteParams = {
  filter?: string;
};

type Props = CompositeScreenProps<
  StackScreenProps<SubscriptionsStackRouteParams, 'Subscriptions'>,
  AppDrawerScreenProp
>;

export const SubscriptionsScreen: React.FC<Props> = ({ route }) => {
  const { I18n } = useI18n();
  const { colors } = useTheme();
  const { state } = useAuth();
  const [filter, setFilter] = React.useState<string | null>(route.params?.filter || null);

  const getProductsResult = useGetProducts();

  const products = getProductsResult.data?.data?.getProducts?.filter((product) => !!product) ?? [];

  const filteredProducts = filter
    ? products.filter((sub) => (filter === 'active' ? !!sub.subscription : !sub.subscription))
    : products;

  const renderNoSubscriptionsContent = () => {
    return getProductsResult.isLoading ? (
      <Loading />
    ) : (
      <Typography variant="body1" style={{ paddingHorizontal: 16, paddingVertical: 8 }}>
        {filter === 'active' ? t('subscriptions.noActiveSubscriptions') : t('subscriptions.noProducts')}
      </Typography>
    );
  };

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('subscriptions.summaryTitle'),
        description:
          route.params?.filter === 'active'
            ? I18n.t('subscriptions.activeSummaryDescription')
            : I18n.t('subscriptions.summaryDescription'),
        icon: 'basket',
      }}
    >
      {state !== AuthState.GUEST_LOGIN && !route.params?.filter && (
        <View style={{ flexDirection: 'row', paddingLeft: 16, paddingBottom: 8, alignItems: 'center' }}>
          <UiIcon size={24} name="sliders" set="fontawesome6" color={colors.onSurface} />
          <FlatList
            data={['active', 'inactive']}
            horizontal
            renderItem={({ item }) => {
              const selected = filter === item;
              return (
                <Chip
                  style={{ marginLeft: 8, backgroundColor: selected ? colors.primary : undefined }}
                  mode={selected ? 'flat' : 'outlined'}
                  selectedColor={selected ? colors.inverseOnSurface : undefined}
                  selected={selected}
                  onPress={(e) => (selected ? setFilter(null) : setFilter(item))}
                >
                  {I18n.t(`subscriptions.filter.${item}`)}
                </Chip>
              );
            }}
          />
        </View>
      )}
      {/* TODO: Should subscriptions be grouped by region or something? */}
      {filteredProducts.length
        ? filteredProducts?.map((product) => (
            <SubscriptionItem
              key={product.id}
              name={product.name ?? ''}
              productId={product.id ?? ''}
              price={product.subscription?.price ?? product.price}
              owner={product.owner?.name}
              currency={(product.subscription?.currency as Currency) || Currency.EUR}
              active={!!product.subscription}
              canceled={product.subscription?.status === ProductSubscriptionStatus.CANCELLED}
              renewalDate={product.subscription?.renewalDate}
              renewInterval={product.renewInterval ?? ProductRenewInterval.MONTHLY}
            />
          ))
        : renderNoSubscriptionsContent()}
    </ScrollablePage>
  );
};
