import React, { useCallback } from 'react';
import { CompositeScreenProps, useNavigation } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import ActivePaymentMethodRow from '../MapView/PaymentMethodRow';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { SubscriptionsStackRouteParams } from '../../navigators/SubscriptionsStackNavigator';
import { screenNames } from '../../navigators/screenNames';
import { ListItemRow } from '../../components/ListItemRow';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { Currency, ProductRenewInterval, ProductSubscriptionStatus } from '../../types/appsync-types';
import Typography from '../../components/Typography';
import GradientButton from '../../components/GradientButton';
import { useActivePayment } from '../../payment/MapViewActivePaymentContext';
import Spacer from '../../components/Spacer';
import { AuthState, useAuth } from '../../context/AuthContext';
import { useGetProductById } from '../../apis/subscriptionApis';
import Loading from '../../components/Loading';
import { PaymentMethodOption } from '../../types/misc.types';
import { getRenewIntervalTranslationKeys } from '../../utils/getRenewIntervalTranslationKeys';

function SpaceItem(props: { title: string; description: string; spaceId: string }) {
  const { title, description, spaceId } = props;
  const { I18n } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const onPress = useCallback(() => {
    navigation.navigate(screenNames.SpaceInfo, {
      spaceId,
    });
  }, [navigation, spaceId]);

  return <ListItemRow type="navigation" title={title} description={description} onPress={onPress} />;
}

export type SubscriptionDetailRouteParams = {
  productId?: string;
};

type Props = CompositeScreenProps<
  StackScreenProps<SubscriptionsStackRouteParams, 'SubscriptionDetail'>,
  AppDrawerScreenProp
>;

export const SubscriptionDetailScreen: React.FC<Props> = ({ route }) => {
  const { I18n, formatDate, formatCurrency } = useI18n();
  const { state, logoutAsGuest } = useAuth();
  const { timeZone } = useMapViewFilter();
  const { activePaymentMethod } = useActivePayment();
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const getProductByIdResult = useGetProductById(route.params.productId);

  // TODO: Handle product being undefined
  const currentProduct = getProductByIdResult.data?.data?.getProductById;

  const currentSubscription = currentProduct?.subscription;

  const isSubscriptionActive = !!currentSubscription;

  const isSubscriptionCancelled = currentSubscription?.status === ProductSubscriptionStatus.CANCELLED;

  const spaces = currentProduct?.linkedPods;
  // TODO: Switch to date format that uses month names for clarity?
  const renewalDateString = currentSubscription?.renewalDate
    ? formatDate(new Date(currentSubscription.renewalDate), 'dateWithYear', timeZone)
    : '';

  return (
    <ScrollablePage
      summary={{
        title: currentProduct?.name ?? I18n.t('subscriptions.detail.title'),
        description: currentSubscription?.startDate
          ? (currentProduct?.owner?.name ? `${currentProduct.owner.name}\n\n` : '') +
            I18n.t('subscriptions.detail.activeSince', {
              date: formatDate(new Date(currentSubscription.startDate), 'dateWithYear', timeZone),
            })
          : currentProduct?.owner?.name ?? I18n.t('subscriptions.detail.summaryDescription'),
        icon: 'calendar',
      }}
    >
      {getProductByIdResult.isLoading ? (
        <Loading />
      ) : (
        <View>
          <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
            <Typography variant="h3">{I18n.t('subscriptions.detail.includedSpacesTitle')}</Typography>
            <Typography variant="body2" style={{ marginVertical: 5 }}>
              {I18n.t('subscriptions.detail.includedSpacesDescription')}
            </Typography>
            {spaces?.map((space) => (
              <SpaceItem
                key={space?.localRef}
                spaceId={space?.localRef ?? 'unknown'}
                title={space?.title ?? 'Unknown'}
                description={space?.building?.title ?? ''}
              />
            ))}
          </View>
          <View style={{ flexDirection: 'column', marginTop: 20, paddingHorizontal: 20 }}>
            <Typography variant="h6">
              {formatCurrency(
                currentSubscription?.price ?? currentProduct?.price,
                currentSubscription?.currency || Currency.EUR,
              ) +
                ' / ' +
                I18n.t(
                  getRenewIntervalTranslationKeys(currentProduct?.renewInterval ?? ProductRenewInterval.MONTHLY)
                    .singular,
                )}
            </Typography>
            <Spacer />
            {state === AuthState.GUEST_LOGIN ? (
              <GradientButton
                onPress={() => {
                  logoutAsGuest();
                }}
              >
                {I18n.t('subscriptions.detail.loginToUse')}
              </GradientButton>
            ) : (
              <View>
                <ActivePaymentMethodRow
                  paymentMethod={
                    currentSubscription?.card
                      ? { paymentMethodType: PaymentMethodOption.CARD, stripeCard: currentSubscription.card }
                      : activePaymentMethod
                  }
                  onPress={() => navigation.navigate(screenNames.PaymentChangeBeforeCheckoutView)}
                  readOnly={isSubscriptionActive}
                />
                {isSubscriptionActive && (
                  <Typography variant="body1" style={{ paddingBottom: 16 }}>
                    {isSubscriptionCancelled
                      ? I18n.t('subscriptions.detail.cancellationEffectiveOn', {
                          date: renewalDateString,
                        })
                      : I18n.t('subscriptions.detail.nextBillingDate', {
                          date: renewalDateString,
                        })}
                  </Typography>
                )}
                {!isSubscriptionCancelled && (
                  <GradientButton
                    icon={!isSubscriptionActive ? 'credit-card' : undefined}
                    disabled={!isSubscriptionActive && !activePaymentMethod}
                    onPress={() => {
                      if (!isSubscriptionActive) {
                        navigation.navigate(screenNames.ConfirmSubscriptionDialog, { id: currentProduct?.id });
                      } else {
                        navigation.navigate(screenNames.ConfirmSubscriptionCancelDialog, {
                          id: currentSubscription?.id,
                        });
                      }
                    }}
                  >
                    {isSubscriptionActive
                      ? I18n.t('subscriptions.detail.cancel')
                      : I18n.t('subscriptions.detail.subscribe')}
                  </GradientButton>
                )}
              </View>
            )}
          </View>
        </View>
      )}
    </ScrollablePage>
  );
};
