import React, { useCallback, useMemo, useState } from 'react';
import { HelperText, useTheme } from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import type { ApplePayError, ConfirmPaymentError, GooglePayError, StripeError } from '@stripe/stripe-react-native';
import { CompositeScreenProps, useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Alert } from 'react-native';
import { useI18n } from '../../context/I18nContext';
import { flattenDict } from '../../utils/helpers';
import { createSubscription, invalidateSubscriptionData, useGetProductById } from '../../apis/subscriptionApis';
import { PaymentMethodOption } from '../../types/misc.types';
import { useStripe } from '../../payment/utils/stripe';
import ConfirmationRow from '../../payment/components/ConfirmationRow';
import Container from '../../components/Container';
import { NavigationDialog } from '../CommonDialogs';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { CardPaymentMethod, useActivePayment } from '../../payment/MapViewActivePaymentContext';
import { trackMakeSubscription } from '../../utils/mixpanelUtils';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import * as Sentry from '../../components/sentry/sentry';
import {
  Currency,
  Product,
  ProductRenewInterval,
  ProductStatus,
  ProductSubscription,
  ProductSubscriptionStatus,
} from '../../types/appsync-types';
import { SubscriptionsStackRouteParams } from '../../navigators/SubscriptionsStackNavigator';
import { getRenewIntervalTranslationKeys } from '../../utils/getRenewIntervalTranslationKeys';
import { AgreeToTermsRow } from '../../payment/components/AgreeToTermsRow';
import { screenNames } from '../../navigators/screenNames';

export interface ConfirmSubscriptionDialogRouteParams {
  id: string;
}

export type SubscriptionFailureHandler = (
  type: 'PAYMENT' | 'CANCELLED' | 'UNEXPECTED',
  data: ProductSubscription | undefined | null,
  stripeError?: StripeError<ConfirmPaymentError | GooglePayError | ApplePayError>,
) => void;

type Props = CompositeScreenProps<
  StackScreenProps<SubscriptionsStackRouteParams, 'ConfirmSubscriptionDialog'>,
  AppDrawerScreenProp
>;

export function ConfirmSubscriptionDialog({ navigation, route }: Props) {
  const isScreenFocused = useIsFocused();
  const { activePaymentMethod, isActivePaymentMethodLoading } = useActivePayment();
  const { colors } = useTheme();
  const mp = useMixpanel();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const { I18n, formatCurrency } = useI18n();
  const [userAgreedToTerms, setUserAgreedToTerms] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const getProductByIdResult = useGetProductById(route.params.id);

  // TODO: Handle product being undefined
  const product = getProductByIdResult.data?.data?.getProductById;

  const handleUserDismissed = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSubscriptionSucceeded = useCallback(
    (makeAProductSubscription: ProductSubscription, selectedProduct: Product) => {
      trackMakeSubscription(mp, makeAProductSubscription, selectedProduct);

      // Sparing a bit time to try to eliminate the async update of reservation
      setTimeout(() => {
        invalidateSubscriptionData(queryClient);
      }, 1000);
      // TODO: Make sure, if possible, that user can't come back to this screen by navigating forward
      navigation.goBack();
    },
    [mp, navigation, queryClient],
  );

  const handleSubscriptionFailed: SubscriptionFailureHandler = useCallback(
    (type, makeAProductSubscription, stripeError) => {
      mp?.getPeople().increment('Subscription failures', 1);
      if (type === 'CANCELLED') {
        mp?.track('Subscription creation cancelled', flattenDict('subscription', makeAProductSubscription));
        setErrorMessage(
          `${I18n.t('subscriptions.confirmSubscriptionModal.subscriptionCancelled.title')}: ${I18n.t(
            'subscriptions.confirmSubscriptionModal.subscriptionCancelled.description',
          )}`,
        );
      } else if (stripeError) {
        mp?.track('Subscription creation failed', flattenDict('error', stripeError));
        setErrorMessage(stripeError.localizedMessage ?? stripeError.message);
      } else {
        mp?.track('Subscription creation failed', flattenDict('subscription', makeAProductSubscription));
        setErrorMessage(`${I18n.t('subscriptions.confirmSubscriptionModal.subscriptionFailMainContent')}`);
      }
    },
    [mp, I18n],
  );

  const handleSubscriptionWithCard = useCallback(
    async (createdSubscription: ProductSubscription): Promise<void> => {
      try {
        const confirmPaymentResult = { paymentIntent: true, error: null }; // TODO: DONT BYPASS
        // const confirmPaymentResult = await stripe.confirmPayment(createdSubscription?.paymentSecret!, {
        //   paymentMethodType: 'Card',
        //   paymentMethodData: { paymentMethodId: (activePaymentMethod as CardPaymentMethod).stripeCard.id },
        // });
        if (confirmPaymentResult.paymentIntent) {
          // TODO: properly handle product being null | undefined
          onSubscriptionSucceeded(createdSubscription, product!);
        } else {
          throw confirmPaymentResult.error;
        }
      } catch (error) {
        console.error('Payment failed', error);
        mp?.getPeople().increment('Payment failures', 1);
        mp?.track('Subscription creation payment failed', {
          ...flattenDict('subscription', createdSubscription),
          ...flattenDict('error', error),
        });
        Sentry.captureException(error);
        handleSubscriptionFailed('PAYMENT', createdSubscription, error as StripeError<ConfirmPaymentError>);
      }
    },
    [stripe, activePaymentMethod, onSubscriptionSucceeded, product, mp, handleSubscriptionFailed],
  );

  const makeASubscriptionMutation = useMutation(
    async (input: Parameters<typeof createSubscription>) => {
      console.log('input', input);
      return createSubscription(...input);
    },
    {
      async onSuccess(data) {
        switch (data.data?.makeAProductSubscription?.status) {
          case ProductSubscriptionStatus.FAILED:
            handleSubscriptionFailed('PAYMENT', data.data?.makeAProductSubscription);
            break;
          case ProductSubscriptionStatus.MISSING_PAYMENT:
            mp?.track(
              'Subscription creation pending payment',
              flattenDict('subscription', data.data?.makeAProductSubscription),
            );
            // activePaymentMethod can not be null, because user cannot click payment button without active payment method
            if (!activePaymentMethod) {
              Alert.alert(I18n.t('payment.alert.noActivePaymentMethod'));
              return;
            }
            if (activePaymentMethod.paymentMethodType === PaymentMethodOption.CARD) {
              await handleSubscriptionWithCard(data.data.makeAProductSubscription);
            }
            break;

          case ProductSubscriptionStatus.ACTIVE:
            // TODO: properly handle product being null | undefined
            onSubscriptionSucceeded(data.data?.makeAProductSubscription, product!);
            break;
          default:
            handleSubscriptionFailed('UNEXPECTED', data.data?.makeAProductSubscription);
            break;
        }
      },
      onError(error: any) {
        /* Could not do pre-payment-booking */
        const errorCode = error?.errors?.[0]?.message;
        console.error(errorCode);
        handleSubscriptionFailed('UNEXPECTED', null);
      },
    },
  );

  const onConfirm = useCallback(async () => {
    if (!product) throw new Error('Product is undefined!');

    if (activePaymentMethod?.paymentMethodType === PaymentMethodOption.CARD) {
      // The actual logic of what to do after this, is in the mutations' success / fail handlers.
      await makeASubscriptionMutation.mutateAsync([
        {
          input: {
            productId: product.id,
            cardId: activePaymentMethod.stripeCard.id,
          },
        },
      ]);
    } else {
      setErrorMessage(`${I18n.t('payment.alert.noActivePaymentMethod')}`);
    }
  }, [product, activePaymentMethod, makeASubscriptionMutation, I18n]);

  if (!isScreenFocused) {
    return null;
  }

  return (
    <NavigationDialog
      style={{ backgroundColor: colors.elevation.level2 }} // TODO: is this correct?
      title={I18n.t('subscriptions.confirmSubscriptionModal.title')}
      description={''}
      onDismiss={handleUserDismissed}
      actions={[
        {
          title: I18n.t('subscriptions.confirmSubscriptionModal.cancel'),
          onPress: handleUserDismissed,
          disable: makeASubscriptionMutation.isLoading,
        },
        {
          title: I18n.t('subscriptions.confirmSubscriptionModal.confirm'),
          loading: makeASubscriptionMutation.isLoading || isActivePaymentMethodLoading,
          disable: !product || !userAgreedToTerms,
          onPress: () => {
            return onConfirm();
          },
        },
      ]}
      iconName={'check'}
    >
      {/* Product */}
      {/* TODO: What icon? */}
      <ConfirmationRow title={product?.name ?? '?'} iconName="calendar" />

      <ConfirmationRow
        iconName="wallet"
        title={
          formatCurrency(product?.price, Currency.EUR) +
          ' / ' +
          I18n.t(getRenewIntervalTranslationKeys(product?.renewInterval ?? ProductRenewInterval.MONTHLY).singular)
        }
      />

      <AgreeToTermsRow
        text={I18n.t('subscriptions.confirmSubscriptionModal.terms')}
        onValueChange={setUserAgreedToTerms}
        onTermsClick={() =>
          navigation.navigate(screenNames.ParkingTerms, {
            url: I18n.t('subscriptions.confirmSubscriptionModal.termsUrl'),
          })
        }
        value={userAgreedToTerms}
      />

      {/* Error text */}
      <Container>
        <HelperText type={'error'} visible={!!errorMessage}>
          {errorMessage}
        </HelperText>
      </Container>
    </NavigationDialog>
  );
}
