import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { HelperText, useTheme } from 'react-native-paper';
import Typography from '../../components/Typography';
import Switch from '../../components/ui/Switch';
import { useI18n } from '../../context/I18nContext';
import { ModalRowStyle } from './ConfirmationRow';

interface AgreeToTermsRowProps {
  text: string;
  disabled?: boolean;
  onValueChange: (v: boolean) => void;
  onTermsClick: () => void;
  value: boolean;
  errorText?: string;
  style?: StyleProp<ViewStyle>;
}

export function AgreeToTermsRow({
  disabled,
  text,
  onTermsClick,
  onValueChange,
  value,
  errorText,
  style,
}: AgreeToTermsRowProps) {
  const { colors } = useTheme();
  const { I18n } = useI18n();
  return (
    <View>
      <View style={[ModalRowStyle, { justifyContent: 'space-between', flexWrap: 'wrap' }, style]}>
        <View>
          <Typography variant={'sub2'} color={colors.onSurfaceVariant}>
            {I18n.t('payment.agreeToTerms.agree')}{' '}
            <Typography
              color={colors.onBackground}
              variant="sub2"
              style={[{ textDecorationLine: 'underline' }]}
              onPress={onTermsClick}
            >
              {text}
            </Typography>
          </Typography>
        </View>
        <Switch onValueChange={onValueChange} value={!disabled && value} disabled={disabled} />
      </View>
      <HelperText
        style={{ textAlign: 'left', paddingStart: 0 }}
        type={'error'}
        visible={disabled && errorText !== undefined}
      >
        {errorText}
      </HelperText>
    </View>
  );
}
